import React, { useState } from 'react'
import { Col, Row, Button, Modal, Typography } from 'antd'
import { motion } from 'framer-motion'

function FloatTextCol(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Col span={12}>
      <motion.div
        initial={{ y: 300, opacity: 0 }}
        whileInView={{
          y: 0,
          opacity: 1,
          transition: { type: 'spring', bounce: 0, duration: 1 },
        }}
        viewport={{ once: true }}
      >
        <Row
          justify="center"
          style={{
            padding: '0px 50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography.Title>{props.title}</Typography.Title>
          <Button
            type="primary"
            style={{
              backgroundColor: 'green',
              borderColor: 'green',
              color: '#fff',
              marginTop: '20px',
            }}
            onClick={showModal}
          >
            Learn More
          </Button>
        </Row>
        <Modal
          title={
            <Typography.Title style={{ textAlign: 'center', fontSize: '26px' }}>
              {props.title}
            </Typography.Title>
          }
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Close
            </Button>,
          ]}
          centered
        >
          <div style={{ textAlign: 'center' }}>
            <Typography style={{ fontSize: '15px' }}>
              {props.text}
            </Typography>
          </div>
        </Modal>
      </motion.div>
    </Col>
  )
}

export default FloatTextCol
