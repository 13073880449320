import React from 'react'

import appLogo from '../images/Fruit_of_War_Logo.png'

import coverImage from '../images/cans_mockup.png'

import discordImage from '../images/discord.png'


// Fruits
import apple from '../images/apple_background.png'
import lemon from '../images/lemon_background.png'
import mango from '../images/mango_background.png'
import tangerine from '../images/tangerine_background.png'
import grape from '../images/grape_background.png'


export const initialState = {
    // when in dev, change appURL to local url
    // appURL: 'http://localhost:3000',  
    // when in production, change appURL to real url
    appURL: 'https://fruitofwar.com',

    appLogo: appLogo,
    appName: 'Fruit of War',

    coverTitle: 'Choose your vibe!',
    coverText1: "Welcome to Fruitopia! There are 5 groups of Fruitopians battling to be victorious. Each tribe has their own motivations, exemplary warriors, and unique weapons.",
    coverText2: "You will have a chance to assist your favorite tribe! Every “Fruit of War” drink will be converted into resources to help support your favorite faction by scanning the label’s QR code. Every scan has a direct impact on the campaign's direction and tribe success during that battle season.",
    coverText3: "You have your mission, scan the codes and use the resources. You decide how the war evolves, how you beat your enemies, and help your allies. You choose who is the best “Fruit of War” and YOU. CHOOSE. YOUR. VIBE.",

    coverImage: coverImage,

    endorsementTitle: `Make a difference in the Fruitopia universe!`,
    endorsementText: `Join our mailing list to hear more about the Fruitopia world and be the first to try our newest flavors`,
    endorsementList: [
        {
            // title:
            // titleColor:
            // image:
            // URL:
        },
    ],

    sectionList: [
        {
            'title': `Col. Adam Appleton`,
            'text': `Adam has always lived his life in the fast lane. His first car was already a high octane beast, but when he modded it the car went from beast to speed demon. The only natural next step was the fighter jets. He was a master pilot, the likes of which the sky had never seen before. Then when the scientists developed a new weapon to end the war, but the pilot who used it would have to be gone in an instant. Adam was the only choice and as he dropped the Big Apple from his plane he proved that the speed was within him and that he would use it in the War for Fruitopia.`,
            'image': apple,
        },
        {
            'title': `CDR. Lawrence Lemollo`,
            'text': `Lawrence was the eldest child in a family of farmers. His family, just like the rest of his village, grew lush groves on their fields from dawn to dusk. Then the Citrussian government drafted him for the war. While Lawrence rose through the ranks fighting with his trusty LMG, the farmers eventually revolted. When Lawrence returned home, his village and family were wiped out. On that day he swore vengeance against the Citrussians and leads the Sour Guard in the War for Fruitopia.`,
            'image': lemon,
        },
        {
            'title': `Maj. Giselle Granea`,
            'text': `Born an orphan, Giselle never had an easy childhood. She moved from family to family all throughout her youth, changing schools, hardly making friends, and never seeming to fit in. Thankfully though, she had her brother through it all. [Insert name] was only just a few years older, but looked after Giselle in every way he could, keeping her from any types of harm, and trying to give her a better future. When the war broke out, a rebel fruit faction took him hostage, and brutally juiced him to set an example for the public. From that day onward, Giselle lost a piece of her soul, and what was once a shy and awkward little girl was the most feared soldier on the battlefield.`,
            'image': grape,
        },
        {
            'title': `Marques Mangrol`,
            'text': `A strong body with a soft soul, Marques grew up during the peaceful times of Frangomia. With his father as major during the first war of Frutopia, Marcus is no stranger to combat. Growing up, he was trained like a soldier, as his father made sure he was ready for what was coming. Yet his mother always made sure to remind him of the joys of life and the goodness of the world. She made sure that even while he was a strong fighter, he stayed a kind person. With his spare time, he loved playing with his pet Rabbit, Jacque, who he cherished deeply. When the second war broke out his mother was one of the early casualties. On her deathbed she made him promise to always be a good person. So he made it his mission to protect his country and to help end the War for Fruitopia.`,
            'image': mango,
        },
        {
            'title': `Pvt. Thomas Tangernelle`,
            'text': `Some would say Thomas was naive, he would describe himself as a true believer. He loved his country, he truly did. Citrussia had given him his entire life and he was grateful. So when the war started, he was more than willing to serve his great nation. He excelled at tank combat and was known throughout as the best gunner and driver. Soon his unit was tasked with quelling the Lemon rebellions and that is when his belief was challenged. He couldn’t let the Sour Guard hurt any more innocents but he also hated how the army was treating the Lemon civilians. He realized that he had to change the system he lived in for the better and the only way to do so is to stop the rebels. His tank is armed and his mission is set; Thomas will stop the Sour Guard and lead his country out of the War for Fruitopia.`,
            'image': tangerine,
        },
    ],

    discordImage: discordImage,
    discordLink: '',
    currentUser: null,
    loadingAuthState: true,
}

const initialContext = {
    state: initialState,
    dispatch: () => null,
}

export const Context = React.createContext(initialContext)
