// src/components/Login.js

import React, { useState, useContext } from 'react';
import { Button, Modal, Input, Form, message } from 'antd';
import { GoogleOutlined, MailOutlined } from '@ant-design/icons';
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { auth } from '../firebase';
import { Context } from '../store/Context';

const Login = () => {
  const { state } = useContext(Context);
  const { currentUser } = state;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsSignUp(false);
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      message.success('Successfully logged in with Google!');
      setIsModalVisible(false);
    } catch (error) {
      message.error('Google login failed: ' + error.message);
    }
  };

  const handleEmailAuth = async (values) => {
    setLoading(true);
    try {
      if (isSignUp) {
        // Sign up new user
        await createUserWithEmailAndPassword(auth, values.email, values.password);
        message.success('Successfully signed up!');
      } else {
        // Log in existing user
        await signInWithEmailAndPassword(auth, values.email, values.password);
        message.success('Successfully logged in!');
      }
      setIsModalVisible(false);
    } catch (error) {
      const action = isSignUp ? 'Sign up' : 'Login';
      message.error(`${action} failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      message.success('Successfully signed out!');
    } catch (error) {
      message.error('Sign out failed: ' + error.message);
    }
  };

  const toggleMode = () => {
    setIsSignUp(!isSignUp);
  };

  return (
    <>
      {currentUser ? (
        <Button type="primary" onClick={handleSignOut}>
          Sign Out
        </Button>
      ) : (
        <Button type="primary" onClick={showModal} icon={<MailOutlined />}>
          Login
        </Button>
      )}

      <Modal
        title={isSignUp ? 'Sign Up' : 'Login'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleEmailAuth}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Enter a valid email!' },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
            ]}
          >
            <Input.Password placeholder="Enter your password" />
          </Form.Item>
          {isSignUp && (
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm your password" />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {isSignUp ? 'Sign Up' : 'Login'}
            </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center', marginBottom: '16px' }}>
          {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
          <Button type="link" onClick={toggleMode}>
            {isSignUp ? 'Login' : 'Sign Up'}
          </Button>
        </div>
        <Button
          type="default"
          icon={<GoogleOutlined />}
          onClick={handleGoogleLogin}
          block
        >
          Login with Google
        </Button>
      </Modal>
    </>
  );
};

export default Login;
