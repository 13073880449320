import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import DesktopApp from './Desktop';
import MobileApp from './Mobile';
import { Context, initialState } from './store/Context';
import { reducer } from './store/reducer';
import { useReducer } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 500);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch({
        type: 'AUTH_STATE_CHANGE',
        value: {
          currentUser: user,
          loadingAuthState: false,
        },
      });
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        {isDesktop ? <DesktopApp /> : <MobileApp />}
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
